







































































.ion-icon {
  .icon-inner svg path {
    stroke-width: 70px; } }
